import {
    Button,
    Image,
    makeStyles,
    shorthands,
    tokens,
    Tooltip,
} from "@fluentui/react-components";
import {
    //TextColumnWide24Regular,
    ChevronDoubleLeft20Filled,
    ChevronDoubleRight20Filled,
} from "@fluentui/react-icons";
import { Link } from "react-router-dom";
import { useOrganization } from "../../providers/organization.provider";
import { stringToBrightColor } from "../../helpers/string-to-bright-color";
import { useDrawer } from "../../providers/drawer.provider";
import { useEffect, useRef, useState } from "react";
import oilogo from "../../assets/OILogo.png";

const useStyles = makeStyles({
    header: {
        // ...shorthands.padding("24px", "24px", "8px"),
        ...shorthands.borderBottom("1px", "solid", "#e5e5e5"),
        width: "100%",
        height: '54px',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        columnGap: tokens.spacingHorizontalXS,
    },
    outlookInsightLogo: {
        height: '54px',
        width: '54px'
    },
    hidden: {
        display: "none"
    },
    actions: {
        display: 'flex'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: tokens.spacingHorizontalXS,
        minHeight: '54px',
    },
    icon: {
        marginTop: '6px'
    },
    headerTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '2%',
        marginLeft: '2%',
        // color: 'var(--warm-white)',
        textDecoration: 'none'
    },
    leftPadding: {
        paddingLeft: '8px'
    },
    headerRightText: {
        // fontSize: '80%',
        fontWeight: '600',
        fontStyle: 'none',
        color: 'black',
        lineHeight: '1.5',
        minWidth: '200px',
        textAlign: 'right',
        paddingRight: '8px'
    },
    customerLogo: {
        height: '54px',
        width: '54px'
    },
});

type AppBarProps = {}


export const AppBar = ({ }: AppBarProps) => {
    const styles = useStyles();

    const { organization } = useOrganization();

    const drawer = useDrawer()
    const drawerToggleRef = useRef<HTMLButtonElement>(null);
    const [statusMessage, setStatusMessage] = useState("");

    const openSidebar = () => {
        drawer.toggleDrawer();
    }

    useEffect(() => {
        if (!drawer.isDrawerOpen) {
            drawerToggleRef.current?.focus();
        }
    }, [drawer.isDrawerOpen])

    return (
        <header className={styles.header}>

            <div className={`${styles.headerContainer} ${styles.leftPadding}`}>
                {!drawer.isDrawerOpen ? (
                    <Link to="https://www.outlookinsight.com" aria-label="Outlook Insight's website" className={styles.icon}>
                        <img
                            src={oilogo}
                            className={`${styles.outlookInsightLogo} ${drawer.isDrawerOpen ? styles.hidden : ''}`}
                            alt="Outlook Insight company logo"
                        />
                    </Link>
                ) : null}
                <Tooltip content={drawer.isDrawerOpen ? "Close sidebar menu" : "Open sidebar menu"} relationship="label">
                    <Button
                        ref={drawerToggleRef}
                        appearance="subtle"
                        onClick={openSidebar}
                        icon={drawer.isDrawerOpen ? <ChevronDoubleLeft20Filled /> : <ChevronDoubleRight20Filled />}
                        aria-expanded={drawer.isDrawerOpen}
                    />
                </Tooltip>
            </div>

            {/* spacer */}
            <span></span>
            <div className={styles.headerContainer}>
                {organization && (
                    <Link to={organization?.homeUrl || ""} className={styles.headerTitleContainer} aria-label={`${organization.name} website`}>
                        <span className={styles.headerRightText}>{organization?.name}</span>
                        {/* {organization?.logo ? <img src={organization?.logo} alt={'Customer business logo'} aria-label={`${organization?.name} website`} className={styles.customerLogo} /> : null} */}

                        <Image height={54} width={54} src={
                            organization?.logo || `https://ui-avatars.com/api?name=${organization?.name}&background=${stringToBrightColor(organization?.id)}`
                        } alt={'Customer business logo'} aria-label={`${organization?.name} website`} />
                    </Link>
                )}
            </div>
        </header >
    )
}