export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export const enum OnConflict {
    REPLACE = "overwrite",
    KEEP = "rename",
    FAIL = "fail"
}

export type AskRequestOverrides = {
    retrievalMode?: RetrievalMode;
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    useOidSecurityFilter?: boolean;
    useGroupsSecurityFilter?: boolean;
};

export type Organization = {
    id: string;
    name: string;
    homeUrl: string;
    description: string;
    logo: string;
    consentScreenEnabled: boolean;
    consentScreenText: string;
    consentScreenTitle: string;
    createdAt: string;
    updatedAt: string;
};

export type KnowledgeBase = {
    id: string;
    name: string;
    description: string;
    organizationId: string;
    documentCount: number;
    storageSize: number;
    config: KnowledgeBaseConfig;
    createdAt: string;
    updatedAt: string;
};

export type KnowledgeBaseConfig = {
    id: string;
    knowledgeBaseId: string;
    chatBotTitle: string;
    chatBotSubtitle: string;
    systemMessage: string;
    followUpQuestionsPrompt: string;
    promptTemplate: string;
    published: boolean;
};

export enum FileMetadataStatus {
    PENDING = "PENDING",
    PROCESSING = "PROCESSING",
    PROCESSED = "PROCESSED",
    FAILED = "FAILED",
    DELETING = "DELETING",
    DELETION_FAILED = "DELETION_FAILED"
}

export type KnowledgeBaseResource = {
    id: string;
    name: string;
    size: number;
    source: string;
    type: string;
    url: string;
    knowledgeBaseId: string;
    status: FileMetadataStatus;
    createdAt: string;
    updatedAt: string;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    summary: string;
    error?: string;
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type ChatRequest = {
    knowledgeBaseId: string;
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
    shouldStream?: boolean;
};

export type QuestionExample = {
    id: string;
    text: string;
    value: string;
    knowledgeBaseId: string;
    createdAt: string;
    updatedAt: string;
};

export type Directory = {
    countryCode: string;
    defaultDomain: string;
    displayName: string;
    domains: string[];
    id: string;
    tenantCategory: string;
    tenantId: string;
    tenantType: string;
};

export type User = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    permissions?: Permission[];
};

export type Permission = {
    id: number;
    name: string;
    action: string;
    resource: string;
};
