import { isAxiosError } from "axios"
import { useEffect, useState, useRef } from "react"
import { getKnowledgeBasesRequest } from "../../api/knowledge-base"
import { KnowledgeBase } from "../../api"
import { useMsal } from "@azure/msal-react"
import { Spinner, Text, makeStyles, shorthands } from "@fluentui/react-components"
import { KnowledgeBaseCardSelectable } from "../knowledge-base-card-selectable/knowledge-base-card-selectable"
import { useLocation, useNavigate } from "react-router-dom"
import { ability } from "../../providers/ability.provider"

const useStyles = makeStyles({
    list: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        flexDirection: 'column',
        display: 'flex',
        ...shorthands.gap('16px'),
        minHeight: '300px',
        listStyleType: 'none'
    },
    scrollable: {
        overflow: 'hidden auto',
        height: '100%'
    }
})

export const KnowledgeBases = ({
    selectedKnowledgeBaseId, isCardFooterVisible = true, canCreateKnowledgeBase = false
}: {
    selectedKnowledgeBaseId?: string;
    isCardFooterVisible?: boolean;
    canCreateKnowledgeBase?: boolean;
}) => {
    const styles = useStyles();
    const { instance } = useMsal();
    const navigate = useNavigate();
    const { pathname } = useLocation()

    const activeAccount = instance.getActiveAccount();
    // const { knowledgeBase: selectedKnowledgeBase, selectKnowledgeBase } = useKnowledgeBase()

    const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBase[]>([])
    const [isKnowledgeBasesLoading, setIsKnowledgeBasesLoading] = useState<boolean>(false)
    const [error, setError] = useState<String | null>(null)

    const initialNavigationDone = useRef(false);

    const onSelectKnowledgeBase = (knowledgeBaseId: string) => {
        navigate(`/chat/${knowledgeBaseId}`)
    }

    // const toggleFooterVisibility = () => {
    //     setFooterVisible(!isCardFooterVisible);
    // };

    useEffect(() => {
        const getKnowledgeBases = async () => {
            try {
                setIsKnowledgeBasesLoading(true)

                const knowledgeBases = await getKnowledgeBasesRequest()
                const allKnowledgeBases = knowledgeBases.data;

                // Filter knowledge bases based on the 'published' state and permissions
                const visibleKnowledgeBases = canCreateKnowledgeBase
                    ? allKnowledgeBases
                    : allKnowledgeBases.filter(kb => kb.config.published);

                    setKnowledgeBases(visibleKnowledgeBases);
            } catch (error: unknown) {
                if (isAxiosError(error)) {
                    if (error.response?.data?.status === 401) {
                        instance.logout()
                    } else if (error.response?.data?.message) {
                        setError(error.response?.data?.message)
                    } else {
                        setError('An error occurred while fetching knowledge bases')
                    }
                }
            } finally {
                setIsKnowledgeBasesLoading(false)
            }
        }

        getKnowledgeBases()
    }, [activeAccount?.idTokenClaims?.oid, canCreateKnowledgeBase])

    // Automatically select the first knowledge base if none is selected
    useEffect(() => {
        if (
            knowledgeBases.length > 0 &&
            !selectedKnowledgeBaseId &&
            !initialNavigationDone.current &&
            !pathname.includes('/administration/')
        ) {
            navigate(`/chat/${knowledgeBases[0].id}`);
            initialNavigationDone.current = true; // Mark the initial navigation as done
        }
    }, [knowledgeBases, selectedKnowledgeBaseId, navigate]);

    if (isKnowledgeBasesLoading) return <Spinner label="Loading" tabIndex={0} aria-live="polite" />

    if (error) return <Text>{error}</Text>

    if (knowledgeBases.length === 0) return <Text>You don't have any active knowledge bases. Ask your administrator to create them for you</Text>

    return (
        <ul className={`${styles.list} ${styles.scrollable}`} aria-label="Knowledge bases">
            {knowledgeBases.map(knowledgeBase => (
                <li key={knowledgeBase.id} >
                    <KnowledgeBaseCardSelectable
                        id={knowledgeBase.id}
                        title={knowledgeBase.name}
                        documentCount={knowledgeBase.documentCount}
                        updatedAt={knowledgeBase.updatedAt}
                        published={knowledgeBase.config.published}
                        subtitle={knowledgeBase.description}
                        selected={knowledgeBase.id === selectedKnowledgeBaseId && !pathname.includes('administration')}
                        onClick={() => onSelectKnowledgeBase(knowledgeBase.id)}
                        canCreateKnowledgeBase={canCreateKnowledgeBase}
                        isFooterVisible={isCardFooterVisible}
                    />
                </li>
            ))}
        </ul>
    )
}