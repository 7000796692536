import { Outlet } from 'react-router-dom';
import {
    makeStyles,
    shorthands,
    tokens,
    mergeClasses,
} from "@fluentui/react-components";
import AppBar from "../../features/app-bar";
import Drawer from "../../features/drawer";
import { GuidelinesAndConsent } from '../../features/guidelines-and-consent';
import { DrawerProvider } from '../../providers/drawer.provider';

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        flexGrow: "1",
        height: "100vh",
        // backgroundColor: "#fff",
    },
    content: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: "1",
        alignItems: "flex-start",
        columnGap: tokens.spacingHorizontalXS,
        overflow: "hidden",
    },
    app: {
        // ...shorthands.padding("24px"),
        ...shorthands.flex(1),
        ...shorthands.overflow("auto"),
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        columnGap: tokens.spacingHorizontalXS,
    },
    flexColumn: {
        flexDirection: "column",
    },
});

export const DashboardLayout = () => {
    const styles = useStyles();

    return (
        <div className={mergeClasses(styles.root, styles.flexColumn)}>
            <div className={styles.root}>
                <DrawerProvider>
                    <Drawer
                        // open={drawerOpen}
                        // setOpen={setDrawerOpen}
                        position="start"
                    />

                    <div className={styles.content}>
                        <AppBar />

                        <main role={"main"} className={styles.app}>
                            <div style={{
                                height: "100%",
                                width: '100%',
                                display: "flex",
                                flexDirection: 'column',
                                backgroundColor: 'var(--warm-white)',
                            }}>
                                <Outlet />
                            </div>
                        </main>

                        <GuidelinesAndConsent />
                    </div>
                </DrawerProvider>
            </div>
        </div>
    );
};