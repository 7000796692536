import { AxiosResponse } from "axios";
import axios from "../providers/axios.provider";
import { KnowledgeBase } from "./models";
import { OptionSlots } from "@fluentui/react-components";

export type ReindexRequest = {
    knowledgeBaseId: string;
};

type ReindexResponse = {
    message: string;
};

export async function reindexKnowledgeBaseFetch(options: ReindexRequest): Promise<AxiosResponse<ReindexResponse>> {
    return await axios.post<ReindexResponse>(`/api/knowledge-base/${options.knowledgeBaseId}/reindex`);
}

export type CreateKnowledgeBaseRequest = {
    name: string;
    description?: string;
};

export async function createKnowledgeBaseRequest(options: CreateKnowledgeBaseRequest): Promise<AxiosResponse<KnowledgeBase>> {
    return await axios.post<KnowledgeBase>("/api/knowledge-base", {
        name: options.name,
        description: options.description,
    });
}

// export type PublishKnowledgeBaseRequest = {
//     published: boolean | false;
// };

export async function publishKnowledgeBaseRequest(
    id: string, 
    chatBotTitle: string, 
    chatBotSubtitle: string, 
    description: string, 
    name: string,
    published: boolean
): Promise<AxiosResponse<KnowledgeBase>> {

    const url = `/api/knowledge-base/${id}`;

    try {
        console.log('Request URL:', url);
        console.log('Request Payload:', { 
            published: published,
            name: name,
            description: description,
            chatBotTitle: chatBotTitle,
            chatBotSubtitle: chatBotSubtitle
        });
        
        const response = await axios.patch(url, {
            published: published,
            name: name,
            description: description,
            chatBotTitle: chatBotTitle,
            chatBotSubtitle: chatBotSubtitle
        });

        console.log('Response Data:', response.data);
        return response;
    } catch (error: any) {
        console.error('Error Response:', error.response ? error.response.data : error);
        throw error;
    }
}

export type GetKnowledgeBaseRequest = {
    knowledgeBaseId: string;
};

export async function getKnowledgeBaseRequest(options: GetKnowledgeBaseRequest): Promise<AxiosResponse<KnowledgeBase>> {
    return await axios.get<KnowledgeBase>(`/api/knowledge-base/${options.knowledgeBaseId}`);
}

export async function getKnowledgeBasesRequest(): Promise<AxiosResponse<KnowledgeBase[]>> {
    return await axios.get<KnowledgeBase[]>("/api/knowledge-base");
}

export type UpdateKnowledgeBaseRequest = {
    name: string;
    description?: string;
    chatBotTitle: string;
    chatBotSubtitle: string;
    published?: boolean;
    systemMessage?: string | null;
    promptTemplate?: string | null;
    followUpQuestionsPrompt?: string | null;
};

export async function updateKnowledgeBaseRequest(id: string, options: UpdateKnowledgeBaseRequest): Promise<AxiosResponse<KnowledgeBase>> {
    const url = `/api/knowledge-base/${id}`;

    return await axios.patch(url, {
        name: options.name,
        description: options.description,
        followUpQuestionsPrompt: options.followUpQuestionsPrompt,
        chatBotTitle: options.chatBotTitle,
        published: options.published,
        chatBotSubtitle: options.chatBotSubtitle,
        promptTemplate: options.promptTemplate,
        systemMessage: options.systemMessage
    });
}

export type DeleteKnowledgeBaseRequest = {
    knowledgeBaseId: string;
};

export async function deleteKnowledgeBaseRequest(options: DeleteKnowledgeBaseRequest): Promise<AxiosResponse<void>> {
    return await axios.delete(`/api/knowledge-base/${options.knowledgeBaseId}`);
}
